import React from 'react';
import Colors from '../constants/ColorConstants';

const CheckFailedIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="8090" width={size} height={size} {...props}>
    <path d="M897.28 85.333333h-750.933333c-28.16 0-51.2 23.04-51.2 51.2v750.933334c0 28.16 23.04 51.2 51.2 51.2h276.906666c18.773333 0 34.133333-15.36 34.133334-34.133334s-15.36-34.133333-34.133334-34.133333H163.413333V153.6h716.8v275.626667c0 18.773333 15.36 34.133333 34.133334 34.133333s34.133333-15.36 34.133333-34.133333V136.533333c0-28.16-22.613333-51.2-51.2-51.2z" fill={color} p-id="8091"></path><path d="M649.813333 315.733333h-324.266666c-18.773333 0-34.133333-15.36-34.133334-34.133333s15.36-34.133333 34.133334-34.133333h324.266666c18.773333 0 34.133333 15.36 34.133334 34.133333s-14.933333 34.133333-34.133334 34.133333zM564.48 452.266667h-238.933333c-18.773333 0-34.133333-15.36-34.133334-34.133334s15.36-34.133333 34.133334-34.133333h238.933333c18.773333 0 34.133333 15.36 34.133333 34.133333s-14.933333 34.133333-34.133333 34.133334zM479.146667 588.8h-153.6c-18.773333 0-34.133333-15.36-34.133334-34.133333s15.36-34.133333 34.133334-34.133334h153.6c18.773333 0 34.133333 15.36 34.133333 34.133334s-14.933333 34.133333-34.133333 34.133333zM660.906667 928.853333c-13.226667-13.226667-13.226667-34.986667 0-48.213333l229.12-229.12c13.226667-13.226667 34.986667-13.226667 48.213333 0 13.226667 13.226667 13.226667 34.986667 0 48.213333l-229.12 229.12c-13.226667 13.226667-34.56 13.226667-48.213333 0z" fill={color} p-id="8092"></path><path d="M660.906667 651.093333c13.226667-13.226667 34.986667-13.226667 48.213333 0l229.12 229.12c13.226667 13.226667 13.226667 34.986667 0 48.213334-13.226667 13.226667-34.986667 13.226667-48.213333 0l-229.12-229.12c-13.226667-13.226667-13.226667-34.986667 0-48.213334z" fill={color} p-id="8093"></path>
  </svg>
)

export default CheckFailedIcon;
