import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import Switch from '@material-ui/core/Switch';

// --- Components --- //
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';

// --- Icons --- //
import SearchIcon from '../../icons/SearchIcon';
import CalendarIcon from '../../icons/CalendarIcon';

// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';
import DialogConstants from '../../constants/dialog/index';

// --- Actions --- //
import * as blacklistActionCreator from '../../actions/blacklistActionCreator';
import * as basicConfigActionCreator from '../../actions/basicConfigActionCreator';

// --- Selectors --- //
import * as BlacklistSelectors from '../../selectors/BlacklistSelectors';

import I18N from '../../i18n';
import RDSButton from '../../components/common/RDSButton';
import PagePathConstants from '../../constants/router/PagePathConstants';
import DeleteConfirmationModal from '../../components/common/dialog/DeleteConfirmationModal';
import classNames from 'classnames';
import LocalStorageConstants from '../../constants/LocalStorageConstants';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import { RDSPopover, RDSPopoverBtn } from '../../components/common/popover/index';
import { Pagination } from 'element-react';
import * as DateTimeUtil from '../../utils/DateTimeUtil';

const CACHED_PARAM_KEY = LocalStorageConstants.BLACKLIST_CACHED_PARAM;
class BlacklistPage extends Component {
    constructor(props, context) {
        super(props, context);
        const cachedParam = LocalStorageUtil.loadCachedParam(CACHED_PARAM_KEY);
        this.state = {
            blacklistParams: cachedParam ? cachedParam : {
                page: 1,
                per_page: 10,
                email: '',
                ip: '',
                phone: ''
            },
            currencyList: null,
            showLoading: false,
            showDeleteConfirmModal: false,
            deleteItemId: '',
        };

        this.getBlacklist = this.getBlacklist.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.handleFilterParamsChange = this.handleFilterParamsChange.bind(this);
        this.handleEditBlacklist = this.handleEditBlacklist.bind(this);
        this.handlePreviewDeleteModal = this.handlePreviewDeleteModal.bind(this);
        this.createNewBlacklist = this.createNewBlacklist.bind(this);
        this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
        this.handleDeleteBlacklist = this.handleDeleteBlacklist.bind(this);
        this.searchNewBlacklistRecord = this.searchNewBlacklistRecord.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    }

    componentDidMount() {
        this.getBlacklist();
    }

    toggleDeleteConfirmModal() {
        this.setState({ showDeleteConfirmModal: !this.state.showDeleteConfirmModal });
    }

    handleEditBlacklist(id) {
        const { history } = this.props;
        history && history.push(PagePathConstants.BLACKLIST_EDIT.replace(':id', id));
    }

    handlePreviewDeleteModal(id) {
        this.toggleDeleteConfirmModal();
        this.setState({ deleteItemId: id });
    }

    handleDeleteBlacklist() {
        const { blacklistActions } = this.props;
        const { deleteItemId } = this.state;

        blacklistActions && (deleteItemId && deleteItemId !== '') && blacklistActions.deleteBlacklistInfoById(this.state.deleteItemId)
            .then(data => {
                this.toggleDeleteConfirmModal();
                this.getBlacklist();
                return;
            })
            .catch(this.toggleDeleteConfirmModal);
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleFilterParamsChange(property, e) {
        const value = e && e.target.value;
        const { blacklistParams } = this.state;
        blacklistParams[property] = value;
        this.setState({ blacklistParams }, () => {
            LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, blacklistParams);
        });
    }

    getBlacklist(params) {
        if (!params) {
            params = this.state.blacklistParams;
        }
        const { blacklistActions } = this.props;
        this.toggleLoading();
        return blacklistActions && blacklistActions.getBlacklist(params)
            .then(data => {
                this.toggleLoading();
                return;
            });
    }

    searchNewBlacklistRecord() {
        let { blacklistParams } = this.state;
        blacklistParams.page = 1;
        this.setState({ blacklistParams }, () => {
            this.getBlacklist(blacklistParams);
            LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, blacklistParams);
        });
    }

    handlePageChange(page) {
        let { blacklistParams } = this.state;
        blacklistParams.page = page;
        this.getBlacklist(blacklistParams)
            .then(() => {
                LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, blacklistParams);
                this.setState({ blacklistParams });
            });
    }

    createNewBlacklist() {
        const { history } = this.props;
        history && history.push(PagePathConstants.BLACKLIST_ADD);
    }

    handlePageSizeChange(pageSize) {
        let { blacklistParams } = this.state;
        blacklistParams.per_page = pageSize;
        blacklistParams.page = 1;
        this.getBlacklist(blacklistParams)
            .then(() => {
                LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, blacklistParams);
                this.setState({ blacklistParams });
            });
    }


    render() {
        const { blacklist, blacklistTotalNum } = this.props;
        const { blacklistParams, showLoading, showDeleteConfirmModal } = this.state;
        const total = Math.ceil(blacklistTotalNum / blacklistParams.per_page);
        return (<div className='tw-w-full tw-px-[50px]'>
            {showLoading && <Loading />}
            <DeleteConfirmationModal isDeleteModalOpen={showDeleteConfirmModal} toggle={this.toggleDeleteConfirmModal} handleDeleteConfirm={this.handleDeleteBlacklist} sectionName={DialogConstants.BLACKLIST} />
            <div className='tw-w-full tw-mt-[30px]'>
                <div className='tw-flex tw-justify-between tw-items-center tw-w-full tw-mt-[20px] hs-userlist-filter-container'>
                    <div className='tw-relative'>
                        <EDSInput className="tw-w-[450px] tw-py-[12px] tw-rounded-[30px] tw-px-[20px] tw-border-none tw-shadow-xl"
                            value={blacklistParams.email}
                            type="text"
                            placeholder={I18N.getText('email-placeholder')}
                            onChange={(e) => this.handleFilterParamsChange('email', e)}
                        />
                        <SearchIcon size={24} color={ColorConstants.black[0]} className="tw-absolute tw-right-[20px] tw-bottom-[12px] tw-w-[24px] tw-h-[24px]" onClick={this.searchNewBlacklistRecord} />
                    </div>
                    <div className='tw-w-[300px] tw-flex tw-justify-end tw-items-center'>
                        <RDSButton className="tw-text-white" onClick={this.createNewBlacklist}>Create</RDSButton>
                    </div>
                </div>

                <div className={classNames('tw-w-full tw-mt-[20px] tw-bg-white tw-rounded-[10px]', { 'tw-px-[20px] tw-py-[10px]': blacklistTotalNum > 0 })}>
                    {blacklistTotalNum > 0 && <div className='tw-w-full hs-order-table-container tw-py-[10px] '>
                        <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[12px] tw-mb-[10px] tw-h-[40px] tw-border tw-border-border-gray tw-rounded-[10px] tw-shadow-table'>
                            <div className='tw-w-[15%]'>{I18N.getText('username')}</div>
                            <div className='tw-w-[15%]'>{I18N.getText('ip')}</div>
                            <div className='tw-w-[20%]'>{I18N.getText('order-email')}</div>
                            <div className='tw-w-[20%]'>{I18N.getText('order-phone')}</div>
                            <div className='tw-w-[15%]'>{I18N.getText('date')}</div>
                            <div className='tw-w-[15%]'>{I18N.getText('operation')}</div>
                        </div>
                        <div className='tw-w-full tw-text-[12px]'>
                            {blacklist && blacklist.map(record => {
                                return (<div className='tw-h-[50px] tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-mb-[10px] hs-table-row tw-border tw-border-border-gray tw-rounded-[10px] tw-shadow-table'>
                                    <div className='tw-w-[15%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center tw-px-[10px]'>
                                            <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.name}</span>
                                        </div>
                                    </div>
                                    <div className='tw-w-[15%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center tw-px-[10px]'>
                                            <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.ip}</span>
                                        </div>
                                    </div>
                                    <div className='tw-w-[20%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center tw-px-[10px]'>
                                            <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.email}</span>
                                        </div>
                                    </div>
                                    <div className='tw-w-[20%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center tw-px-[10px]'>
                                            <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.phone}</span>
                                        </div>
                                    </div>

                                    <div className='tw-w-[15%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center'>
                                            <CalendarIcon size={24} color={ColorConstants.black[0]} />
                                            <span className='tw-text-[12px] tw-ml-[5px]  hs-td-cell'>{DateTimeUtil.showFullTimeFormat(record.updatetime * 1000)}</span>
                                        </div>
                                    </div>
                                    <div className='tw-w-[15%] tw-flex tw-justify-center tw-items-center'>

                                        <RDSPopover strategy='fixed' buttonAs='button' className='tw-inline-block tw-relative hs-popover'>
                                            <div className='tw-w-auto tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-brand-primary-light tw-flex tw-items-center tw-justify-center tw-text-white'>
                                                View Detail
                                            </div>
                                            <div role="menu" className='tw-w-[110px] tw-absolute tw-top-[20px] tw-left-[-10px] tw-flex tw-items-center tw-justify-center tw-bg-white tw-px-[10px] tw-py-[6px] tw-rounded-[6px] tw-shadow-2xl'>
                                                <RDSPopoverBtn as='button' className={`dropdown-item tw-flex tw-justify-center tw-items-center`} onClick={() => this.handleEditBlacklist(record.id)} >
                                                    <SvgIcon size={20} color={ColorConstants.brand.primary} name="edit-icon" />
                                                </RDSPopoverBtn>

                                                {<RDSPopoverBtn as='button' className={`dropdown-item tw-flex tw-justify-center tw-items-center`} onClick={() => this.handlePreviewDeleteModal(record.id)} >
                                                    <SvgIcon size={18} color={ColorConstants.brand.primary} name="trash-icon" />
                                                </RDSPopoverBtn>}
                                            </div>
                                        </RDSPopover>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>}
                </div>
                {blacklistTotalNum === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
                    <SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
                    <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
                </div>}
                {blacklistTotalNum > 0 && <div className='tw-w-full tw-flex tw-justify-center tw-mt-[20px]'>
                    <Pagination total={blacklistTotalNum} pageSize={blacklistParams.per_page} currentPage={blacklistParams.page} layout='sizes,prev,pager,next,jumper,->,total' pageSizes={[10, 20, 30, 50]} onCurrentChange={this.handlePageChange} onSizeChange={this.handlePageSizeChange} />
                </div>}
            </div>
        </div>);
    }
}

function mapStateToProps(state) {
    return {
        blacklist: BlacklistSelectors.selectBlacklist(state),
        blacklistTotalNum: BlacklistSelectors.selectBlacklistRecordTotalNum(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        blacklistActions: bindActionCreators(blacklistActionCreator, dispatch),
        basicConfigActions: bindActionCreators(basicConfigActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BlacklistPage);

