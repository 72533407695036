import React from 'react';
import Colors from '../constants/ColorConstants';

const CheckIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="5708" width={size} height={size} {...props}>
    <path d="M898.433097 1023.236675 125.566903 1023.236675C108.914976 1023.236675 95.41558 1009.737279 95.41558 993.085352L95.41558 289.300037C95.41558 281.900368 98.085689 275.127007 102.509155 269.880295 104.538454 266.701048 107.011244 263.650421 109.913786 260.836807L363.910059 14.65507C369.271269 6.30239 378.633446 0.763325 389.295565 0.763325L898.433097 0.763325C915.085024 0.763325 928.58442 14.262721 928.58442 30.914648L928.58442 993.085352C928.58442 1009.737279 915.085024 1023.236675 898.433097 1023.236675ZM400.745434 62.931918 184.518464 272.506895 400.745434 272.506895 400.745434 62.931918ZM868.281774 61.065971 461.048081 61.065971 461.048081 302.658218C461.048081 319.310145 447.548684 332.809542 430.896757 332.809542 430.320066 332.809542 429.747954 332.789695 429.179277 332.757635 428.6106 332.789695 428.038488 332.809542 427.461796 332.809542L155.718226 332.809542 155.718226 962.934029 868.281774 962.934029 868.281774 61.065971ZM457.958906 711.006366 662.607004 506.189573 714.061191 557.620097 509.411566 762.438035 509.418436 762.444905 458.035238 813.89413 310.194904 666.114481 361.578484 614.665255 457.958906 711.006366Z" p-id="5709" fill={color}></path>
  </svg>
)

export default CheckIcon;
