
import ActionConstants from '../constants/actions/ActionConstants';
import * as GraphAPI from '../apis/GraphAPI';
import * as OrderAPI from '../apis/OrderAPI';
import { showErrorGlobalAlert } from './globalAlertActionCreator';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';
import moment from 'moment';


export const updateSuccessfulOrderGraphAction = (data) => ({
    type: ActionConstants.UPDATE_SUCCESSFUL_ORDER_GRAPH,
    payload: data,
});

export const updateFailedOrderGraphAction = (data) => ({
    type: ActionConstants.UPDATE_FAILED_ORDER_GRAPH,
    payload: data,
});

export const updateWithdrawGraphAction = (data) => ({
    type: ActionConstants.UPDATE_WITHDRAW_AMOUNT_GRAPH,
    payload: data,
});

export const updateOrderNumGraphAction = (data) => ({
    type: ActionConstants.UPDATE_SUCCESSFUL_ORDER_NUM_GRAPH,
    payload: data,
});

export const updateOrderStatisticsDataAction = (data) => ({
    type: ActionConstants.UPDATE_ORDER_STATISTICS_DATA,
    payload: data,
});



// export const fetchExpenditure = (unit, callback) => {
//     return function (dispatch, getState) {
//         return new Promise((resolve, reject) => {
//             const dashboardState = getState().get(ReducerNameConstants.DASHBOARD);
//             const startTime = Math.floor((dashboardState.get(StoreStateConstants.FILTER_START_DATE).valueOf()) / 1000);
//             const endTime = Math.floor((dashboardState.get(StoreStateConstants.FILTER_END_DATE).valueOf()) / 1000);

//             // MOCK data
//             let amountArr = [];
//             if (unit.id === 1) {
//                 const weekNum = Math.ceil((endTime - startTime) / 1000 / (7 * 24 * 60 * 60));
//                 const weekMS = (7 * 24 * 60 * 60) * 1000;
//                 let start = startTime
//                 for (let i = 0; i < weekNum; i++) {
//                     let data = {
//                         startTime: start,
//                         endTime: start + weekMS,
//                         value: 0,
//                     }
//                     if (i > 0 && i % 10 == 0) {
//                         data.value = 300 + (i * 10)
//                     }
//                     amountArr.push(data);
//                     start = start + weekMS;
//                 }
//                 console.log(amountArr.length)
//                 console.log(amountArr)
//             }
//             if (unit.id === 0) {
//                 let startMonth = moment(startTime).startOf("month").valueOf();
//                 let endMonth = moment(endTime).endOf("month").valueOf();
//                 let startTimestamp = moment(startMonth);
//                 let i = 0;
//                 while (startTimestamp <= endMonth) {
//                     i++
//                     let data = {
//                         startTime: startTimestamp.valueOf(),
//                         endTime: startTimestamp.add(1, 'M').valueOf(),
//                         value: 0,
//                     }
//                     if (i > 0 && i % 3 == 0) {
//                         data.value = 300 + (i * 10)
//                     }
//                     amountArr.push(data);
//                 }
//                 console.log(amountArr.length)
//                 console.log(amountArr)
//             }
//             dispatch(updateExpenditureAction({
//                 data: amountArr,
//                 total: 97.75
//             }));
//         });
//     };
// };


export const fetchOrderAmountGraphData = (params, callback) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            params['type'] = 'order_amount';
            GraphAPI.fetchGraphData(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        const amountArr = res.data.data.list;
                        if (params.status === 1) {
                            dispatch(updateSuccessfulOrderGraphAction({
                                data: amountArr,
                            }));
                        }
                        if (params.status === 6) {
                            dispatch(updateFailedOrderGraphAction({
                                data: amountArr,
                            }));
                        }
                        callback && callback();
                    }
                })
                .catch((err) => {
                    callback && callback();
                    dispatch(showErrorGlobalAlert('dashboard-amount-error'));
                });
        });
    };
};

export const fetchWithdrawAmountGraphData = (params, callback) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            params['type'] = 'withdraw';
            GraphAPI.fetchGraphData(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        const amountArr = res.data.data.list;
                        dispatch(updateWithdrawGraphAction({
                            data: amountArr,
                        }));
                        callback && callback();
                    }
                })
                .catch((err) => {
                    callback && callback();
                    dispatch(showErrorGlobalAlert('dashboard-amount-error'));
                });
        });
    };
};

export const fetchOrderNumGraphData = (params, callback) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            params['type'] = 'order_num';
            GraphAPI.fetchGraphData(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        const amountArr = res.data.data.list;
                        dispatch(updateOrderNumGraphAction({
                            data: amountArr,
                        }));
                        callback && callback();
                    }
                })
                .catch((err) => {
                    callback && callback();
                    dispatch(showErrorGlobalAlert('dashboard-amount-error'));
                });
        });
    };
};

export const fetchOrderStatisticsData = (params, callback) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            OrderAPI.getOrderStatisticsData(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        dispatch(updateOrderStatisticsDataAction(res.data.data));
                        callback && callback();
                    }
                })
                .catch((err) => {
                    callback && callback();
                    dispatch(showErrorGlobalAlert('dashboard-amount-error'));
                });
        });
    };
};